<template>
  <v-card
    elevation="0"
    class="px-0"
    style="display: flex; flex-direction: column;height:100%;"
    
  >
    <div class="chatheader  px-3" style="width:100%" :style="'padding-top:' + ($root.sysbarheight) + 'px'">
      <v-btn
          style=""
          icon
          color="primary"
          @click="closechat()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="px-2" style="
    display: flex;
    align-items: center;
">
      <v-avatar color="primary">
        <div
          style="text-transform: uppercase;width:100%"
          class=" white--text font-size-h4"
        >
          {{ remoteuser.first_name.charAt(0) }}
        </div>
      </v-avatar>
      <div :class="'px-3'">
        <div class="text-dark-75 font-weight-bolder font-size-lg"
          >{{ remoteuser.first_name }} {{ remoteuser.last_name }}</div
        >
        <div
          v-show="typingstatus"
          class="text-muted font-weight-bold font-size-sm"
          >typing</div
        >
      </div>
        </div>
    </div>

    <perfect-scrollbar class="messages p-5">
      <template v-for="(item, index) in chat">
        <div
          :id="'c' + item.id"
          v-if="item.user.id == myinfo.id"
          :class="' d-flex flex-column align-items-start'"
          :key="item.user.id + '_' + index"
        >
          <div
            class="mt-2 p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg max-w-400px"
            style="border-radius: 0px 16px 16px 16px !important;"
          >
            {{ item.msg }}
          </div>
        </div>
        <div
          :id="'c' + item.id"
          v-else
          :class="' d-flex flex-column align-items-end'"
          :key="item.user.id + '_' + index"
        >
          <div
            class="mt-2 p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg max-w-400px min-w-60px"
            style="border-radius: 16px 0px 16px 16px !important;"
          >
            {{ item.msg }}
          </div>
        </div>
      </template>
    </perfect-scrollbar>

    <div ref="textmessage" class="bg-white chatbottom" style="display:flex" :style="
          'padding-bottom: ' + $root.tabspadding + 'px' ">

      <v-textarea
        solo
        flat
        v-model="chatText"
        label="Write here"
        rows="2"
        auto-grow
        @keydown.enter.exact.prevent
        @keyup.enter.exact="onSubmit"
        hide-details
        ref="chatxt"
        style="width: 85%;"
      ></v-textarea>
      <v-btn
        icon
        fab
        color="primary"
        
        :disabled="chatText == ''"
        
        v-on:click.prevent="onSubmit"
        ><v-icon>mdi-send</v-icon></v-btn
      >
    </div>
  </v-card>
</template>

<script>
export default {
  name: "Chat",
  props: ["remoteuser"],
  components: {},
  data() {
    return {
      ds: this.$deepstream,
      chat: [],
      chatText: "",
      myinfo: {},
      typingstatus: false,
      typingcounter: 0,
      chatroom: null
    };
  },
  computed: {},
  created() {
    // console.log({ chatroom: this.chatroom, remoteuser: this.remoteuser });
    this.myinfo = {
      id: JSON.parse(localStorage.userInfo).id,
      name:
        JSON.parse(localStorage.userInfo).first_name +
        " " +
        JSON.parse(localStorage.userInfo).last_name
    };

    // this.chatroom = this.remoteuser.id + '-' + this.myinfo.id;

    this.ds.login({}, async () => {
      console.log("logged in");
    });

    // load previous chat
    this.loadPrevChat();

    /** Listen to new entries */
    // const list = this.ds.record.getList(`chat-${this.chatroom}`);
    // list.on('entry-added', (recordName) => {
    //   this.ds.record.getRecord(recordName).whenReady((record) => {
    //     record.subscribe((data) => {
    //       this.chat.push(data);
    //     }, true);
    //   });
    // });

    // ================================================

    // const { record } = this.ds.record.getRecord('chat');
    // // this.chat.push(record.record.data);
    // console.log({ record });

    // subscribe to new messages
    this.event = this.ds.event;
    this.event.subscribe(this.chatroom, value => {
      if (value.user.id != this.myinfo.id) this.typingstatus = value.typing;
      if (value.msg) {
        this.chat.push(value);
        this.scrolltosection();
        this.typingstatus = value.typing;
      }
    });
  },
  watch: {
    chatText: function(newtext) {
      this.event.emit(this.chatroom, { user: this.myinfo, typing: true });
      setTimeout(() => {
        if (this.chatText == newtext)
          this.event.emit(this.chatroom, { user: this.myinfo, typing: false });
      }, 1000);
    },
    remoteuser: function() {
      this.loadPrevChat();
    }
  },
  mounted() {},
  destroyed() {},
  methods: {
    closechat()
    {
      this.$emit("closechat");
    },
    typing(e) {
      if (e.code == "Enter") {
        // this.onSubmit();
        this.onSubmit();
      }
    },
    loadPrevChat() {
      this.chat = [];
      this.chatroom =
        this.remoteuser.id < this.myinfo.id
          ? this.remoteuser.id + "-" + this.myinfo.id
          : this.myinfo.id + "-" + this.remoteuser.id;
      // this.chatroom = this.remoteuser.id + '-' + this.myinfo.id;
      console.log("chatroom", this.chatroom);
      // console.log(this.remoteuser);

      const list = this.ds.record.getList(`chat-${this.chatroom}`);
      console.log({ list });
      list.whenReady(list => {
        /** Fetch all existing entries */
        const entries = list.getEntries();
        console.log({ entries });

        for (let i = 0; i < entries.length; i++) {
          this.ds.record.getRecord(entries[i]).whenReady(record => {
            // console.log('record', record);
            record.subscribe(data => {
              // if (
              //   data.roomId == this.chatroom ||
              //   data.roomId == this.myinfo.id + '-' + this.remoteuser.id
              // ) {
              //   this.chat.push(data);
              // }
              this.chat.push(data);

              // data.room == this.chatroom && this.chat.push(data);
              // console.log('test', this.chat);
            }, true); //true invokes callback for the first time as well.
          });
        }

        // /** Listen to new entries */
        // const list = this.ds.record.getList('chat');
        // list.on('entry-added', (recordName) => {
        //   this.ds.record.getRecord(recordName).whenReady((record) => {
        //     record.subscribe((data) => {
        //       this.chat.push(data);
        //     }, true);
        //   });
        // });
      });
    },
    // onSubmit() {
    //   // try {
    //   //   // const t = this.ds.record.
    //   //   // const record = this.ds.record.getRecord('chat');
    //   //   const record = this.ds.record.set({
    //   //     user: this.myinfo,
    //   //     msg: this.chatText,
    //   //     typing: false,
    //   //   });
    //   //   console.log({ record2: record });
    //   // } catch (error) {
    //   //   console.error('deep error', error);
    //   // }

    //   this.event.emit(this.chatroom, {
    //     id: new Date() / 1000,
    //     user: this.myinfo,
    //     msg: this.chatText,
    //     typing: false,
    //   });
    //   this.chatText = '';
    // },

    /** add a new Message to chat */
    // async sendMessage() {
    //   // if(!this.user){
    //   //   return alert("Enter your name, to proceed.");
    //   // }
    //   try {
    //     const uid = Math.floor(Math.random() * 1000); //temp way for demo
    //     const recordName = `message/${uid}`; //create a unique record name
    //     const record = await this.ds.record.getRecord(recordName);

    //     const recordSent = record.set({
    //       id: new Date() / 1000,
    //       user: this.myinfo,
    //       msg: this.chatText,
    //       typing: false,
    //     });
    //     const list = this.ds.record.getList('chat');
    //     list.addEntry(recordName); //add to the list
    //     console.log({ recordSent, list });
    //     this.chatText = '';
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // =================================
    /** sets user's status */
    // onKey(ev){
    //   this.userStatus = ev.target.value;
    // },

    /** add a new post to timeline */
    onSubmit() {
      if (!this.chatText) return;
      // const uid = Math.floor(Math.random() * 1000); //temp way for demo
      const uid = this.ds.getUid();
      // const uid = this.chatroom;
      const recordName = `message/${uid}`; //create a unique record name
      const record = this.ds.record.getRecord(recordName);
      record.whenReady(record => {
        // data has now been loaded
        record.set({
          roomId: this.chatroom,
          id: new Date() / 1000,
          user: this.myinfo,
          msg: this.chatText
          // typing: false,
        });
        const list = this.ds.record.getList(`chat-${this.chatroom}`);
        list.addEntry(recordName); //add to the list

        // emit new message
        this.event.emit(this.chatroom, {
          id: new Date() / 1000,
          user: this.myinfo,
          msg: this.chatText,
          typing: false
        });
        this.chatText = "";
      });
    },

    /** Sets user's name */
    // setUser(ev){
    //   this.user = ev.target.value;
    // },

    scrolltosection() {
      //window.scrollTo(0,document.querySelector(".chat").scrollHeight);
      this.$nextTick(function() {
        var container = this.$el.querySelector(".messages");
        //console.log(container);
        if (container.lastElementChild != null)
          container.scrollTop = container.scrollHeight;
      });
      //var element = container.lastElementChild;
      //if(element != null)
      //element.scrollIntoView();
    }
  }
};
</script>
<style scoped>
/* width */

.ps {
  flex-grow: 3;
}
.chatheader {
  flex-shrink: 0;
  display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
}
.chatbottom {
  width: 100%;
  flex-shrink: 0;
  min-height: 8vh;
      padding-top: 5px;
      padding-bottom: 5px;
    border-top-style: solid;
    border-top-width: thin;
    border-top-color: #eef0f8;
}
</style>
