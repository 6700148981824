<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    right
    width="450px"
    temporary
    style="z-index:10000"
  >
  <div v-if="remote_user == null" class="display-1 mx-5 mt-7">Chat with other doctors</div>
    <div class="justify-space-between">
      <div v-if="remote_user" style="height:100vh">
        <div style="position: absolute;z-index: 100;">
          <v-btn
            class="mx-2 mt-3"
            fab
            elevation="0"
            color="white"
            @click="remote_user = null"
          >
            <v-icon large color="primary">
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </div>
        <Chat v-if="remote_user" :remoteuser="remote_user" ref="chatRef" />
      </div>
      <div v-else style="">
        <v-card tile :disabled="doctorsLoading" elevation="0">
          <v-list two-line style="width:100%">
            <v-subheader class="mt-7"
              ><v-text-field
                filled
                single-line
                rounded
                prepend-inner-icon="mdi-magnify"
                label="Search For Doctor"
                v-model="search"
              ></v-text-field
            ></v-subheader>
            <v-list-item-group v-model="selectedDoctor" color="primary">
              <template v-for="(doctor, i) in FilteredDoctors">
                <v-list-item :key="i" @click="doctorSelection(doctor)">
                  <v-list-item-avatar size="45">
                    <v-img
                      :src="doctor.doctor_id.avatar.data.thumbnails[0].url"
                      v-if="doctor.doctor_id.avatar"
                    ></v-img>
                    <v-avatar size="45" color="primary" v-else>
                      <div
                        style="text-transform: uppercase;width:100%;"
                        class=" white--text font-size-h4"
                      >
                        {{ doctor.doctor_id.first_name.charAt(0) }}
                      </div>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-h6">{{
                      doctor.doctor_id.first_name +
                        " " +
                        doctor.doctor_id.last_name
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="font-size-lg">{{
                      doctor.department.name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  class="my-0"
                  dark
                  inset
                  v-if="i < doctors.length - 1"
                  :key="10000 + i"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Chat from "./chat";

export default {
  props: ["chatdialog"],
  components: {
    Chat,
  },
  data() {
    return {
      selectedDoctor: null,
      room: "1-2",
      remote_user: null,
      doctorsLoading: false,
      doctors: [],
      drawer: this.$root.chatOpen,
      search: "",
    };
  },
  methods: {
    doctorSelection(selectedDoctor) {
      console.log(selectedDoctor);
      this.remote_user = {
        id: selectedDoctor.doctor_id.id,
        first_name: selectedDoctor.doctor_id.first_name,
        last_name: selectedDoctor.doctor_id.last_name,
      };
    },
    async getDoctors() {
      try {
        this.doctorsLoading = true;
        console.log("doctorid", this.$root.docID);
        const { data } = await ApiService.get(
          "items/doctor?fields=id,doctor_id.id,doctor_id.first_name,doctor_id.last_name,doctor_id.avatar.data,department.name&filter[doctor_id.status]=active&filter[id][neq]=" +
            this.$root.docID
        );

        this.doctors = data.data;
        this.doctorsLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    drawer: function(newdrawer) {
      if (!newdrawer) {
        this.$root.chatOpen = newdrawer;
      }
    },
  },
  mounted() {
    this.getDoctors();
  },
  computed: {
    FilteredDoctors() {
      return this.doctors.filter((i) => {
        var nameupper = i.doctor_id.first_name + " " + i.doctor_id.last_name;
        var namelower = (
          i.doctor_id.first_name +
          " " +
          i.doctor_id.last_name
        ).toLowerCase();
        return (
          (this.search != "" && nameupper.includes(this.search)) ||
          namelower.includes(this.search)
        );
      });
    },
  },
};
</script>

<style></style>
